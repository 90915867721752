<template>
  <main>
    <div class="app-width">
      <h1>
        <TheStoryFolderLogo />
      </h1>
      <h3 class="gradient">FREE for 6 Months + 50% off for life</h3>
      <h3 class="white">
        Exclusive offer for members of the<br /><strong>Real Deal Video Strategy Club</strong>
      </h3>
      <div class="discount">
        Use promo code <strong>REALDEAL</strong> at checkout
      </div>
      <div>
        <Button class="special uppercase" @click="buy">CLAIM DEAL</Button>
      </div>
    </div>
  </main>
</template>
<script lang="ts">
  import Vue from 'vue';
  import Button from "@/components/atoms/Button.vue";
  import TheStoryFolderLogo from "@/components/molecules/TheStoryFolderLogo.vue";

  export default Vue.extend({
    components: {
      TheStoryFolderLogo,
      Button
    },
    methods: {
      buy(){
        window.open('https://buy.stripe.com/cN216m8XT6ba4VO6oo')
      }
    }
  });
</script>
<style scoped lang="less">
  @import '../less/variables';
  main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url(/img/backgrounds/gradient-background.jpg);
    background-size: cover;
    background-position: center center;
    height: 100vh;
    overflow: auto;
  }
  .gradient{
    background: -webkit-linear-gradient(45deg, #a7e5e0, #e0b2f5, #fc6169);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .app-width{
    max-width: 675px;
    overflow: auto;
    padding-top: 2em;
    padding-bottom: 2em;
  }
  .discount{
    background: rgba(255,255,255, 0.1);
    display: inline-block;
    clear: both;
    padding: 1em 2em;
    border-radius: 0.5em;
    border:2px dashed rgba(255,255,255, 0.2);
  }
  button{
    font-size: 1.5em;
  }
</style>
